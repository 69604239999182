import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['query', 'item', 'empty'];

  get hideClass() {
    return this.data.has('hideClass') ? this.data.get('hideClass') : 'hidden';
  }

  get hideAttribute() {
    return this.data.get('hideAttribute');
  }

  connect() {
    this.tokenizeItems();
    this.applyQuery();
    $(this.queryTarget).on('search', this.handleSearch);
  }

  disconnect() {
    $(this.queryTarget).off('search', this.handleSearch);
  }

  handleSearch = () => {
    this.applyQuery();
  };

  tokenizeItems() {
    this.tokenizedItems = this.itemTargets.map((item) => this.#tokenizeItem(item));
  }

  #tokenizeItem(item) {
    return { element: item, token: extractTextContent(item).toLowerCase().trim() };
  }

  itemTargetConnected(target) {
    this.tokenizedItems || (this.tokenizedItems = []);
    this.tokenizedItems.push(this.#tokenizeItem(target));
  }

  itemTargetDisconnected(target) {
    this.tokenizedItems = this.tokenizedItems.filter(({ element }) => element !== target);
  }

  applyQuery() {
    const queryTokens = this.queryTarget.value.toLowerCase().split(/\s+/);

    if (queryTokens.length === 0) {
      return;
    }

    const matchingItems = [];
    const nonMatchingItems = [];

    this.tokenizedItems.forEach(({ element, token }) => {
      if (queryTokens.every((queryToken) => token.includes(queryToken))) {
        matchingItems.push(element);
      } else {
        nonMatchingItems.push(element);
      }
    });

    $(matchingItems).removeClass(this.hideClass);
    $(nonMatchingItems).addClass(this.hideClass);
    $(matchingItems).removeAttr(this.hideAttribute);
    $(nonMatchingItems).attr(this.hideAttribute, true);

    $(this.hasEmptyTarget && this.emptyTarget).toggle(matchingItems.length === 0);
  }
}

function extractTextContent(node) {
  if (node.nodeType === Node.TEXT_NODE) return node.nodeValue;
  if (node.nodeType === Node.ELEMENT_NODE && node.hasAttribute('data-search-list-ignore')) return '';

  let textContent = '';

  node.childNodes.forEach((child) => (textContent += extractTextContent(child)));

  return textContent;
}
