import { application } from '../bundle';
import { removeModal } from '../stimulus_controllers/modal_controller';

document.addEventListener('turbo:before-stream-render', function (e) {
  const turboStream = e.target;

  if (turboStream.action == 'close_modal') {
    e.preventDefault();

    const modalId = turboStream.getAttribute('target');
    removeModal(application.getControllerForElementAndIdentifier(document.getElementById(modalId), 'modal'));
  }

  if (turboStream.action === 'highlight_replace' || turboStream.action === 'highlight_update') {
    turboStream.setAttribute('action', turboStream.action.replace('highlight_', ''));
    const children = turboStream.templateElement.content.children;
    [...children].forEach((node) => addStimulusController(node, 'highlight'));
  }

  if (turboStream.action == 'redirect_to') {
    e.preventDefault();

    const redirectPath = turboStream.getAttribute('location');

    if (window.location.pathname === redirectPath) {
      window.location.reload();
    } else {
      window.location.pathname = redirectPath;
    }
  }

  if (turboStream.action === 'replace_url') {
    e.preventDefault();
    const url = turboStream.getAttribute('url');
    window.history.replaceState({}, '', url);
  }
});

function addStimulusController(node, controllerName) {
  const stimulusControllers = [controllerName];

  if (node.getAttribute('data-controller')) {
    stimulusControllers.push(...node.getAttribute('data-controller').split(' '));
  }

  node.setAttribute('data-controller', stimulusControllers.join(' '));
}
