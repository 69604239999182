module.exports = {
  // Remember to also add these to the styleguide utilities page (see styleguide_utilities.jsx)
  cursor: {
    help: 'help',
    move: 'move',
  },
  textColor: {
    disabled: '#8a8a8a',
    muted: '#707070',
    default: '#161E2E', // gray-900
    link: '#1c64f2', // blue-600
    info: '#1c64f2', // blue-600
    success: '#057a4a', // green-600
    danger: '#e02424', // red-600
    highlight: '#6c2bd9', // purple-700
    warning: '#ff5a1f', // orange-500
  },
  colors: {
    inherit: 'inherit',
    current: 'currentColor',
    background: 'var(--colors-background)',
    text: 'var(--colors-text)',
    primary: 'var(--colors-primary)',
    'text-on-primary': 'var(--colors-text-on-primary)',
    secondary: 'var(--colors-secondary, var(--colors-primary))',
    'text-on-secondary': 'var(--colors-text-on-secondary, var(--colors-text-on-primary))',
    neutral: 'var(--colors-text)',
    'text-on-neutral': 'var(--colors-background)',
    mix: 'color-mix(in srgb, var(--colors-background), var(--colors-text) calc(<alpha-value> * 100%))',
  },
  spacing: {
    popover: '0.5rem',
  },
  borderRadius: {
    popover: '0.75rem',
    container: 'var(--border-radius-container, 0.25rem)',
    half: 'var(--border-radius-half)',
    button: 'var(--border-radius-button)',
  },
  fontFamily: {
    base: 'var(--font-family-base, sans-serif)',
    headings: 'var(--font-family-headings, var(--font-family-base))',
    headline: 'var(--font-family-headline, var(--font-family-headings))',
    alt: 'var(--font-family-alt, var(--font-family-base))',
  },
  boxShadow: (theme) => ({
    highlight: `0 0 0 3px rgba(118, 169, 250, 0.45), 0 0 0 1px ${theme('colors.blue.500')}`,
    popover: '0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 2px 5px 0px rgba(54, 65, 82, 0.08)',
  }),
  typography: (theme) => ({
    DEFAULT: {
      css: {
        color: theme('colors.gray.900'),
        a: {
          color: theme('colors.blue.500'),
          fontWeight: 'normal',
          '&:hover': {
            color: theme('colors.blue.500'),
            textDecoration: 'underline',
          },
        },
      },
    },
  }),
};
